// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thank-you-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(315deg, #030a21 71.44%, #0fb995 100%);
    margin: 0;
  }
  
  .thank-you-box {
    background-color: white;
    padding: 50px;
    height: 300px;
    width:300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .thank-you-box h1 {
    font-size: 3rem; /* Large font */
    font-weight: bold;
    color: #333;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    .thank-you-box{
        height: 200px;
    width:200px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/css/ThankYouPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iEAAiE;IACjE,SAAS;EACX;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,aAAa;IACb,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,wCAAwC;IACxC,kBAAkB;EACpB;;EAEA;IACE,eAAe,EAAE,eAAe;IAChC,iBAAiB;IACjB,WAAW;IACX,SAAS;EACX;;EAEA;IACE;QACI,aAAa;IACjB,WAAW;IACX;EACF","sourcesContent":[".thank-you-container {\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: linear-gradient(315deg, #030a21 71.44%, #0fb995 100%);\n    margin: 0;\n  }\n  \n  .thank-you-box {\n    background-color: white;\n    padding: 50px;\n    height: 300px;\n    width:300px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    text-align: center;\n  }\n  \n  .thank-you-box h1 {\n    font-size: 3rem; /* Large font */\n    font-weight: bold;\n    color: #333;\n    margin: 0;\n  }\n\n  @media screen and (max-width: 768px) {\n    .thank-you-box{\n        height: 200px;\n    width:200px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
