import React from 'react';
import styles from './css/LoginPage.module.css';

function LoginPage() {
    return (
        <div className={styles.container}>
            <div className={styles.formContainer}>
                <h2 className={styles.title}>Login</h2>
                <form>
                    <div className={styles.inputGroup}>
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" className={styles.inputField} />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="password">Password:</label>
                        <input type="password" id="password" name="password" className={styles.inputField} />
                    </div>
                    <button type="submit" className={styles.submitButton}>Login</button>
                </form>
            </div>
        </div>
    );
}

export default LoginPage;
