import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Certificate from './Certificate';
import Loginpage from './LoginPage';
import ThankYou from './Thankyou';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Certificate />} />
                <Route path="/login" element={<Loginpage />} />
                <Route path="/thank-you" element={<ThankYou />} />
            </Routes>
        </Router>
    );
}

export default App;
