import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import axios from 'axios'
import styles from './css/certificate.module.css';
import logo from './assets/logo.png'
import back from './assets/back.png'
import downarrow from './assets/downarrow.png'
import Loader from './loader';
import countryCodesList from './countrycodes';

const CertificateEditor = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [countryCode, setcountryCode] = useState('+91');
  const [phoneNumber, setphoneNumber] = useState('');
  const [qualification, setQualification] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 728);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 728);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const WEBDOMAINI = process.env.REACT_APP_WEBDOMAIN;
  useEffect(() => {
    setIsButtonEnabled(name !== '' && email !== '' && qualification !=='' && phoneNumber!=='');
  }, [name, email,qualification,phoneNumber]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name,"name");
    console.log(value,"value");
    
    if (name === 'name') setName(value);
    if (name === 'qualification') setQualification(value);
    if (name === 'phoneNumber') setphoneNumber(value);
    if (name === 'countryCode') setcountryCode(value);
    if (name === 'email') {
      setEmail(value);
      validateEmail(value);
    }
  };
  
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };



  const capitalizeFirstLetter = (string) => {
    return string.replace(/\b\w/g, char => char.toUpperCase());
  };

  const checkUserExistence = async (email) => {
    try {
      const response = await axios.post(`${WEBDOMAINI}/api/users/check`, { email });
      return response.data.userExists;
    } catch (error) {
      console.error('Error checking user existence:', error);
      throw error;
    }
  };



  const handleDownload = async () => {

    if (!hasAgreedToTerms) { 
      setErrorMessage("Please agree to the terms."); 
      return;
    }

      if (emailError) {
      setErrorMessage(emailError);
      return;
    }
    setIsLoading(true);
    setIsDownloaded(false);
    setErrorMessage('');
    // setSuccessMessage('');
    try {
      await new Promise(resolve => setTimeout(resolve, 500));
          const formattedName = capitalizeFirstLetter(name);
          const phonenumbertosend = `${countryCode}-${phoneNumber}`;
          const userExists = await checkUserExistence(email);
          
          setSuccessMessage(userExists ? 'Your Certificate has been successfully downloaded' : 'Your Certificate has been successfully downloaded. \nA copy of it has also been emailed');

          setName('');
          setEmail('');
          setQualification('');          
          setphoneNumber('+91')
      const response = await axios.post(`${WEBDOMAINI}/api/users/create`, {
        name: formattedName,
        email: email,
        color: '#000000',
        phoneNumber:phonenumbertosend,
        qualification:qualification
      }, {
        responseType: 'blob'
      });
        console.log('Data sent to backend:', response.data);
  
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      setIsDownloaded(true)
      saveAs(pdfBlob, `${name}_certificate.pdf`);
      navigate('/thank-you');
    } catch (error) {
          setErrorMessage('Error generating PDF');
          console.error('Error generating PDF:', error);
        } finally {
          setIsLoading(false);
        }
      };

  return (
    <div className={styles.parentContainer}>
    <div className={styles.container}>
    
      <div className={styles.formContainer}>
      <div className={styles.imgcontainer}>
        <img src={logo} alt="logo" />
      </div>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={name}
          onChange={handleInputChange}
          className={styles.inputField}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={handleInputChange}
          className={styles.inputField}
        />
      <div className={styles.phoneContainer}>
      <div className={styles.dropdownContainer}>
        <select
        
          name="countryCode"
          value={countryCode}
          onChange={handleInputChange}
          className={styles.countryCodeDropdown}
        >
            {countryCodesList.map((country) => (
          <option key={`${country.code}-${country.name}`} value={country.code}>
            {country.code} &nbsp;&nbsp;({country.name})
          </option>
        ))}
        </select>
        
        </div>
        <input
          type="tel"
          name="phoneNumber"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={handleInputChange}
          className={styles.phoneNumberInput}
        />
      </div>
          <select
        name="qualification"
        value={qualification}
        onChange={handleInputChange}
        className={styles.inputField}
      >
        <option value="" disabled>Select Qualification</option>
        <option value="Pharmacy">Pharmacy</option>
        <option value="Medicine">Medicine</option>
        <option value="Dentistry">Dentistry</option>
        <option value="Physiotherapy">Physiotherapy</option>
        <option value="Nursing">Nursing</option>
        <option value="Optometry">Optometry</option>
        <option value="Laboratory Technologist">Laboratory Technologist</option>
      </select>
        
        {isLoading && <Loader />}
      
        {(isDownloaded && successMessage) && <p className={styles.successMessage}>{successMessage}</p>}
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

          <div className={styles.disclaimerSection}>
    <h3 className={styles.disclaimerHeader}>Disclaimer</h3>
    <ul className={styles.disclaimerList}>
      <li>Academically offers coaching services specifically for Licensing Examinations and does not provide any Visa and Job services.</li>
      <li>This event is organised solely for the purpose of raising awareness about Licensing Examinations and Careers abroad for healthcare professionals.</li>
      <li>The information provided by Academically is based on the current rules and regulations of the respective licensing countries. These regulations are subject to change depending on the circumstances in those countries.</li>
    </ul>
    <p className={styles.disclaimerText}>By checking the box below, I acknowledge and agree to the terms stated above.</p>

    <div className={styles.checkboxContainer}>
      <input
        type="checkbox"
        id="disclaimerCheckbox"
        name="disclaimer"
        checked={hasAgreedToTerms}
  onChange={() => setHasAgreedToTerms(!hasAgreedToTerms)}
        required
        className={styles.checkbox}
      />
      <label htmlFor="disclaimerCheckbox">I agree to the terms of the disclaimer.</label>
    </div>
  </div>
        <button
          onClick={handleDownload}
          disabled={!isButtonEnabled}
          className={`${styles.button} ${isButtonEnabled ? styles.buttonEnabled : styles.buttonDisabled} ${isButtonEnabled ? styles.buttonHoverEnabled : ''}`}
        >
          {isLoading ? 'Downloading...' : isDownloaded ? 'Download Again' : 'Download Certificate'}
        </button>
      </div>
      <div className={styles.rightContainer}>
        <h3 className={styles.thanksMessage}>Thank you for being a part of the event!</h3>
        <p className={styles.downloadMessage}>We love having you with us. Your <strong><em>exclusive participation certificate</em></strong> is ready, please fill in your details to download it!</p>
        <h3 style={{color:"#fff"}}>Register Now</h3>
        <br></br>

        {!isSmallScreen && <img src={back} alt="arrow" />}
        {isSmallScreen && <img src={downarrow} alt="arrow" />}
        
      </div>
    </div>
    </div>
  );
};

export default CertificateEditor;
